export type { Languages } from './type';

import { getLocalizedText } from './getLocalizedText';
import { notFoundText } from './localizedTextNotFound';

export { getLocalizedText } from './getLocalizedText';
export { notFoundText } from './localizedTextNotFound';

export const LocaleUtil = {
  getLocalizedText,
  notFoundText,
};

export default LocaleUtil;
