/**
 * This file is generated by gen-gadget-loader.js
 * Do not edit this file directly.
 */
import * as React from 'react';

export default {
  '@dop-gadgets/approval': React.lazy(() => import('@dop-gadgets/approval')),
  '@dop-gadgets/asset-list': React.lazy(() => import('@dop-gadgets/asset-list')),
  '@dop-gadgets/birthday': React.lazy(() => import('@dop-gadgets/birthday')),
  '@dop-gadgets/board-list': React.lazy(() => import('@dop-gadgets/board-list')),
  '@dop-gadgets/calendar': React.lazy(() => import('@dop-gadgets/calendar')),
  '@dop-gadgets/cartax': React.lazy(() => import('@dop-gadgets/cartax')),
  '@dop-gadgets/community-board': React.lazy(() => import('@dop-gadgets/community-board')),
  '@dop-gadgets/community-list': React.lazy(() => import('@dop-gadgets/community-list')),
  '@dop-gadgets/contract': React.lazy(() => import('@dop-gadgets/contract')),
  '@dop-gadgets/expense': React.lazy(() => import('@dop-gadgets/expense')),
  '@dop-gadgets/favorite-board': React.lazy(() => import('@dop-gadgets/favorite-board')),
  '@dop-gadgets/hr-board': React.lazy(() => import('@dop-gadgets/hr-board')),
  '@dop-gadgets/html-editor': React.lazy(() => import('@dop-gadgets/html-editor')),
  '@dop-gadgets/lecture': React.lazy(() => import('@dop-gadgets/lecture')),
  '@dop-gadgets/login-history': React.lazy(() => import('@dop-gadgets/login-history')),
  '@dop-gadgets/mail-folder': React.lazy(() => import('@dop-gadgets/mail-folder')),
  '@dop-gadgets/mail-list': React.lazy(() => import('@dop-gadgets/mail-list')),
  '@dop-gadgets/notification': React.lazy(() => import('@dop-gadgets/notification')),
  '@dop-gadgets/quickmenu': React.lazy(() => import('@dop-gadgets/quickmenu')),
  '@dop-gadgets/report': React.lazy(() => import('@dop-gadgets/report')),
  '@dop-gadgets/survey': React.lazy(() => import('@dop-gadgets/survey')),
  '@dop-gadgets/timeline': React.lazy(() => import('@dop-gadgets/timeline')),
  '@dop-gadgets/today': React.lazy(() => import('@dop-gadgets/today')),
  '@dop-gadgets/todo': React.lazy(() => import('@dop-gadgets/todo')),
  '@dop-gadgets/vacation': React.lazy(() => import('@dop-gadgets/vacation')),
  '@dop-gadgets/works-chart': React.lazy(() => import('@dop-gadgets/works-chart')),
  '@dop-gadgets/works-list': React.lazy(() => import('@dop-gadgets/works-list')),
};