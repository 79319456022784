import * as React from 'react';

import TempGadget from '../temp-gadgets';
import { GadgetConfig, GadgetProps } from '../../../../../ui/lib/gadget/Gadget';

import type { GadgetApiParams } from '../../types';
import { useDashboardActionsContext } from '../../context';

export interface Props {
  spec: GadgetApiParams;
  isEditMode: boolean;
}

export default function Gadget({ spec, isEditMode }: Props) {
  const context = useDashboardActionsContext();
  const { removeGadget, editGadget, getGadgetComponent } = context;

  const handleDeleteGadgetClick = () => {
    removeGadget(spec.uuid);
  };

  const handleSaveGadgetClick = (options: GadgetConfig) => {
    const newGadget = {
      ...spec,
      option: JSON.stringify(options),
    };
    editGadget(newGadget);
  };

  const gadgetConfig = JSON.parse(spec.option || '{}') as GadgetConfig;

  const baseProps: GadgetProps<GadgetConfig> = {
    editable: isEditMode,
    onDelete: handleDeleteGadgetClick,
    onSave: handleSaveGadgetClick,
    config: gadgetConfig,
    uuid: spec.uuid,
  };

  const renderGadget = () => {
    const Comp = getGadgetComponent(spec.name);

    if (!Comp) {
      return <TempGadget gadget={spec} {...baseProps}></TempGadget>;
    }

    return <Comp {...baseProps} />;
  };

  return (
    <React.Suspense fallback={<div>Gadget Loading...</div>}>
      {renderGadget()}
    </React.Suspense>
  );
}
