'use client';

const DO_PREFIX = process.env['NEXT_PUBLIC_DO_PREFIX'];
const DO_ADMIN_PREFIX = process.env['NEXT_PUBLIC_DO_ADMIN_PREFIX'];

// 인증이 먼저 되어 있어야함. 또는 DO 를 수정.
const SCRIPTS = [
  // {
  //   src: `/esm/vendors/requirejs/require.js`,
  //   deps: [{ src: `/esm/conf/config.js` }, { src: `/esm/conf/env.js` }],
  // },
  { src: '/js/vendors/simplebar/simplebar.min.js' },
  // { src: '/js/vendors/echarts/echarts.min.js' },
  { src: '/js/vendors/realgrid/realgrid.2.4.2.min.js' },
  { src: '/js/vendors/realgrid/realgrid-lic.js' },
];

export interface Style {
  href: string;
  media?: string;
}

export interface Script {
  src: string;
  deps?: Array<Script>;
}

const loadStyle = (style: Style) => {
  const linkEl = document.createElement('link');
  linkEl.rel = 'stylesheet';
  linkEl.type = 'text/css';
  linkEl.href = `${style.href}`;
  linkEl.media = style.media || 'all';
  linkEl.setAttribute('service-type', 'DO');
  const headEl = document.getElementsByTagName('head')[0];
  headEl?.appendChild(linkEl);
};

export const loadScript = (script: Script, prefix: string = '') => {
  const scriptEl = document.createElement('script');
  scriptEl.src = `${prefix}${script.src}`;
  scriptEl.onload = () => {
    script.deps?.forEach((dep) => {
      loadScript(dep, prefix);
    });
  };
  scriptEl.setAttribute('service-type', 'DO');
  scriptEl.setAttribute('type', 'module');
  const headEl = document.getElementsByTagName('head')[0];
  headEl?.appendChild(scriptEl);
};

export const preLoadDOResources = (styles: Array<Style>) => {
  styles.forEach((style) => {
    loadStyle(style);
  });
};

export const loadDOScripts = (prefix: string) => {
  SCRIPTS.forEach((script: Script) => {
    loadScript(script, prefix);
  });
};

export const platformLogin = async () => {
  if (process.env.NODE_ENV === 'development') {
    const referer = process.env.DOP_GATEWAY || '';
    return await fetch(`/api/portal/public/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': referer,
      },
      body: JSON.stringify({
        loginId: process.env.NEXT_PUBLIC_PLATFORM_DEV_LOGIN_ID,
        password: process.env.NEXT_PUBLIC_PLATFORM_DEV_PW,
        companyId: process.env.NEXT_PUBLIC_PLATFORM_DEV_COMPANY_ID,
      }),
    }).catch((e) => {
      console.log(e);
      return Promise.resolve();
    });
  } else {
    return Promise.resolve();
  }
};

export const login = async () => {
  if (process.env.NODE_ENV === 'development') {
    await platformLogin();
    await fetch(`${DO_PREFIX}api/user/session`)
      .then((resp) => {
        return resp.json();
      })
      .then((session) => {
        if (session.code !== '200') {
          return fetch(`${DO_PREFIX}api/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: process.env.NEXT_PUBLIC_DO_DEV_USER_NAME,
              password: process.env.NEXT_PUBLIC_DO_DEV_PW,
            }),
          });
        }
      })
      .catch((e) => console.log(e));
  } else {
    return Promise.resolve();
  }
};

export const adminLogin = async () => {
  if (process.env.NODE_ENV === 'development') {
    // await platformLogin();
    return fetch(`${DO_ADMIN_PREFIX}ad/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: 'mailadm',
        password: '1qaz2wsx#',
      }),
    });
  } else {
    return Promise.resolve();
  }
};
