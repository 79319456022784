import { clsx } from 'clsx';
import { DashboardProps } from '../../types';
import { CogIcon } from '@dop-ui/icons/react/dop/24';
import { useDashboardContext } from '../../context';

export interface Props {
  name: string;
  dashboard: DashboardProps;
  isTempDashboard?: boolean;
  onClick?: () => void;
}

export default function Tab({
  name,
  dashboard,
  onClick = () => undefined,
}: Props) {
  const { actions } = useDashboardContext();
  const { toggleDashboard, setCurrentDashboard } = actions;
  const { dashboardId, updatable, activated, deletable } = dashboard;

  const handleDashboardClick = () => {
    toggleDashboard(dashboardId);
    setCurrentDashboard(dashboard);
  };

  return (
    <li
      className={clsx('flex items-center justify-center gap-x-1', {
        'border-b border-[#333333]': activated,
      })}
      onClick={() => dashboard && void handleDashboardClick()}
      aria-hidden="true"
    >
      <button
        className={clsx({
          'text-[#333333]': activated,
          'text-[#b4b4b4]': !activated,
          'max-w-[150px] text-ellipsis whitespace-nowrap overflow-hidden':
            updatable || deletable,
        })}
      >
        {name}
      </button>
      {(updatable || deletable) && activated && (
        <button onClick={() => onClick()}>
          <CogIcon size={16} />
        </button>
      )}
    </li>
  );
}
