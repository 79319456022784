import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { useValuesContext } from './Context';

export function Wrapper({ children }: PropsWithChildren) {
  const { editable, isEditing, config } = useValuesContext();

  return (
    <div
      className={clsx('go-gadget', {
        wrap_gadget_edit: editable && isEditing,
        gadget_design_border: config.highlight,
      })}
    >
      {children}
    </div>
  );
}

export default Wrapper;
