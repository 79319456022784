import { PropsWithChildren } from 'react';
import { useValuesContext } from './Context';

export interface Props {
  /** @property 가젯 제목 표시 여부 설정 (기본 true) */
  showTitle?: boolean;
}

export function Contents({
  showTitle = true,
  children,
}: PropsWithChildren<Props>) {
  const { title } = useValuesContext();

  const titleBlock = showTitle && (
    <div className="go_gadget_header" style={{ minHeight: '50px' }}>
      <div className="gadget_h1">
        <span className="title">{title}</span>
      </div>
    </div>
  );

  return (
    <div className="go-gadget-content">
      <div className="gadget_design_wrap">
        {titleBlock}
        {children}
      </div>
    </div>
  );
}

export default Contents;
