import { default as Root } from './Root';
export type { Props as RootProps } from './Root';

import { default as Config } from './Config';
export type { Props as ConfigProps } from './Config';

import { default as Contents } from './Contents';
export type { Props as ContentsProps } from './Contents';

import * as Context from './Context';

export const GadgetPrimitives = {
  Root,
  Config,
  Contents,
  Context,
};

export type { GadgetStatus, GadgetConfig, GadgetProps } from './types';
