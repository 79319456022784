import { GadgetDropArea } from './gadget-drop-area';
import GadgetContainer from './gadget-container';
import { GadgetApiParams } from '../../types';
import { useDashboardActionsContext } from '../../context';

export interface Props {
  column: number;
  section: number;
  isEditMode: boolean;
  dashboardId: number;
}

export function GadgetColumn({
  column,
  section,
  isEditMode,
  dashboardId,
}: Props) {
  const context = useDashboardActionsContext();
  const { getGadgetsByColumnSeq } = context;
  const gadgets = getGadgetsByColumnSeq(column - 1) || [];
  return (
    <div
      className={`go-gadget-column gadget-col-${column} gadget_section${section} ui-sortable`}
    >
      {gadgets
        .sort((a, b) => a.sequence - b.sequence)
        .map((gadget: GadgetApiParams, index) => (
          <GadgetContainer
            key={`gadget-${gadget.uuid}`}
            gadget={gadget}
            isEditMode={isEditMode}
            column={column}
            seq={index}
            dashboardId={dashboardId}
          ></GadgetContainer>
        ))}
      {isEditMode && (
        <GadgetDropArea
          column={column}
          seq={gadgets?.length || 0}
          dashboardId={dashboardId}
          isLastArea={true}
        />
      )}
    </div>
  );
}
