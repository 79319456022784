import { useState } from 'react';
import ControlToolbar from './toolbar';
import GadgetSpecList from './gadget-spec-list';

export default function DashboardControl() {
  const [isVisibleGadgetSpecs, setVisibleGadgetSpecs] = useState<boolean>(true);

  return (
    <>
      <ControlToolbar
        toggleGadgetSpecs={(bool) => setVisibleGadgetSpecs(bool)}
        isVisibleGadgetSpecs={isVisibleGadgetSpecs}
      />
      {isVisibleGadgetSpecs && <GadgetSpecList />}
    </>
  );
}
