import * as React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { readGadgetSpecs } from '../../../apis';
import { GadgetSpecProps } from '../../../types';
import { useDashboardContext } from '../../../context';
import GadgetSpecItem from './gadget-spec-item';

function GadgetSpecListInner() {
  const {
    state: { currentDashboard },
    actions: { getGadgetComponent },
  } = useDashboardContext();
  const { data: gadgetSpecs, error } = useSuspenseQuery({
    queryKey: ['gadget-specs'],
    queryFn: readGadgetSpecs,
  });

  if (error || !currentDashboard) {
    console.error(error);
    return;
  }

  const installedGadgets = gadgetSpecs.gadgetTemplateList.filter((spec) =>
    getGadgetComponent(spec.name),
  );

  return (
    <ul className="flex gap-x-[24px] overflow-x-auto px-[24px] py-[20px]">
      {installedGadgets.map((gadgetSpec: GadgetSpecProps) => (
        <GadgetSpecItem
          key={`gadgetSpec-${gadgetSpec.gadgetTemplateId}`}
          spec={gadgetSpec}
          dashboardId={currentDashboard.dashboardId}
        />
      ))}
    </ul>
  );
}

export default function GadgetSpecList() {
  return (
    <React.Suspense fallback={<div>Loding...</div>}>
      <GadgetSpecListInner />
    </React.Suspense>
  );
}
