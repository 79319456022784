import Header from './ui/header';
import { Root, type Props as RootProps } from './ui/root';
import Content from './ui/content';

export * from './types';

export default function Dashboard(props: RootProps) {
  return (
    <Root {...props}>
      <Header />
      <Content />
    </Root>
  );
}
