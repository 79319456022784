/**
 * This file is generated by gen-gadget-loader.js
 * Do not edit this file directly.
 */
import approvalThumb from '@dop-gadgets/approval/thumbnail';
import assetListThumb from '@dop-gadgets/asset-list/thumbnail';
import birthdayThumb from '@dop-gadgets/birthday/thumbnail';
import boardListThumb from '@dop-gadgets/board-list/thumbnail';
import calendarThumb from '@dop-gadgets/calendar/thumbnail';
import cartaxThumb from '@dop-gadgets/cartax/thumbnail';
import communityBoardThumb from '@dop-gadgets/community-board/thumbnail';
import communityListThumb from '@dop-gadgets/community-list/thumbnail';
import contractThumb from '@dop-gadgets/contract/thumbnail';
import expenseThumb from '@dop-gadgets/expense/thumbnail';
import favoriteBoardThumb from '@dop-gadgets/favorite-board/thumbnail';
import hrBoardThumb from '@dop-gadgets/hr-board/thumbnail';
import htmlEditorThumb from '@dop-gadgets/html-editor/thumbnail';
import lectureThumb from '@dop-gadgets/lecture/thumbnail';
import loginHistoryThumb from '@dop-gadgets/login-history/thumbnail';
import mailFolderThumb from '@dop-gadgets/mail-folder/thumbnail';
import mailListThumb from '@dop-gadgets/mail-list/thumbnail';
import notificationThumb from '@dop-gadgets/notification/thumbnail';
import quickmenuThumb from '@dop-gadgets/quickmenu/thumbnail';
import reportThumb from '@dop-gadgets/report/thumbnail';
import surveyThumb from '@dop-gadgets/survey/thumbnail';
import timelineThumb from '@dop-gadgets/timeline/thumbnail';
import todayThumb from '@dop-gadgets/today/thumbnail';
import todoThumb from '@dop-gadgets/todo/thumbnail';
import vacationThumb from '@dop-gadgets/vacation/thumbnail';
import worksChartThumb from '@dop-gadgets/works-chart/thumbnail';
import worksListThumb from '@dop-gadgets/works-list/thumbnail';

export default {
  "@dop-gadgets/approval": approvalThumb,
  "@dop-gadgets/asset-list": assetListThumb,
  "@dop-gadgets/birthday": birthdayThumb,
  "@dop-gadgets/board-list": boardListThumb,
  "@dop-gadgets/calendar": calendarThumb,
  "@dop-gadgets/cartax": cartaxThumb,
  "@dop-gadgets/community-board": communityBoardThumb,
  "@dop-gadgets/community-list": communityListThumb,
  "@dop-gadgets/contract": contractThumb,
  "@dop-gadgets/expense": expenseThumb,
  "@dop-gadgets/favorite-board": favoriteBoardThumb,
  "@dop-gadgets/hr-board": hrBoardThumb,
  "@dop-gadgets/html-editor": htmlEditorThumb,
  "@dop-gadgets/lecture": lectureThumb,
  "@dop-gadgets/login-history": loginHistoryThumb,
  "@dop-gadgets/mail-folder": mailFolderThumb,
  "@dop-gadgets/mail-list": mailListThumb,
  "@dop-gadgets/notification": notificationThumb,
  "@dop-gadgets/quickmenu": quickmenuThumb,
  "@dop-gadgets/report": reportThumb,
  "@dop-gadgets/survey": surveyThumb,
  "@dop-gadgets/timeline": timelineThumb,
  "@dop-gadgets/today": todayThumb,
  "@dop-gadgets/todo": todoThumb,
  "@dop-gadgets/vacation": vacationThumb,
  "@dop-gadgets/works-chart": worksChartThumb,
  "@dop-gadgets/works-list": worksListThumb,
};