/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { GadgetConfig } from '../../../../../ui/lib/gadget/Gadget';
import { GadgetPrimitives } from '../../../../../ui/lib/gadget/Gadget/primitives';
import { LocaleUtil } from '../../../../../ui/lib/gadget/Gadget/locale';
import { TextProps } from './type';
import { GadgetTexts } from './locale';
import type { GadgetApiParams } from '../../types';

export interface Props {
  locale?: string;
  editable: boolean;
  gadget: GadgetApiParams;
  onSave?: (options: GadgetConfig) => void;
  onDelete?: () => void;
  config: GadgetConfig;
  uuid: string;
}

// TODO 임시 가젯. 삭제 예정
export default function Gadget({
  uuid,
  locale = 'ko',
  gadget,
  onSave,
  onDelete,
  config = { highlight: false, refresh: '-1' },
  editable,
}: Props) {
  const localizedText = LocaleUtil.getLocalizedText<TextProps>(
    GadgetTexts,
    locale,
  );

  const handleOnSave = (config: GadgetConfig) => {
    if (onSave) void onSave(config);
    return true;
  };

  return (
    <GadgetPrimitives.Root
      uuid={uuid}
      locale={locale}
      name={
        localizedText
          ? localizedText.gadgetTitle
          : LocaleUtil.notFoundText(locale)
      }
      config={config}
      editable={editable}
      onSave={handleOnSave}
      onDelete={onDelete}
    >
      <GadgetPrimitives.Config isHighlightable isRefreshable />
      <GadgetPrimitives.Contents>{gadget.name}</GadgetPrimitives.Contents>
    </GadgetPrimitives.Root>
  );
}
