import { useState } from 'react';
import { generateUUID } from '../../apis';
import { GadgetApiParams } from '../../types';
import { useDashboardActionsContext } from '../../context';
import { getGadgetDefaultData } from '../header/control/gadget-spec-item';

export interface Props {
  dashboardId: number;
  column: number;
  seq: number;
  isLastArea?: boolean;
  isDragOverParam?: boolean;
  setIsDragOverParam?: (isDragOver: boolean) => void;
}

export function GadgetDropArea({
  column,
  seq,
  isDragOverParam,
  setIsDragOverParam = () => {},
}: Props) {
  const [isDragOver, setIsDragOver] = useState(isDragOverParam);

  const context = useDashboardActionsContext();
  const { reorderGadgets } = context;

  const handleDropMoveGadget = (gadget: GadgetApiParams) => {
    const region = column - 1;
    const changedGadget = {
      ...gadget,
      region,
      sequence: seq,
    };
    reorderGadgets(changedGadget, region, seq);
  };

  const handleDropAddGadget = (gadget: GadgetApiParams) => {
    const region = column - 1;
    const newGadget = {
      ...getGadgetDefaultData(),
      ...gadget,
      region,
      sequence: seq,
      uuid: generateUUID(),
    };
    reorderGadgets(newGadget, region, seq);
  };

  const handleDrop = (e: React.DragEvent) => {
    if (!e.nativeEvent.dataTransfer) return;

    const gadget = JSON.parse(
      e.nativeEvent.dataTransfer.getData('text/plain'),
    ) as GadgetApiParams;

    gadget.uuid ? handleDropMoveGadget(gadget) : handleDropAddGadget(gadget);

    setIsDragOver(false);
    setIsDragOverParam(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    if (!e.nativeEvent.dataTransfer) return;
    e.preventDefault();
    e.nativeEvent.dataTransfer.dropEffect = 'move';
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDragEnter = () => {
    setIsDragOver(true);
  };

  return (
    <div
      className={isDragOver ? 'go-gadget-placeholder' : 'dashboard-box-guide'}
      onDrop={(e) => void handleDrop(e)}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnter={handleDragEnter}
      style={{
        border: '1px dashed rgb(170, 170, 170)',
        minHeight: '100px',
      }}
    />
  );
}
