import { useActionsContext, useValuesContext } from './Context';

export interface Props {
  name: string;
}

export function Header({ name }: Props) {
  const { editable, isEditing } = useValuesContext();
  const { onDelete, setIsEditing } = useActionsContext();

  return (
    <div className="go-gadget-header go_gadget_header">
      <div className="gadget_h1">
        <span className="title">{name}</span>
        {editable && !isEditing && (
          <span className="btn-mgnt btn_side_wrap">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
              className="btn-edit btn_wrap"
              onClick={() => setIsEditing(true)}
            >
              <span className="ic_dashboard2 ic_d_mgmt"></span>
            </span>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span className="btn-remove btn_wrap" onClick={onDelete}>
              <span className="ic_dashboard2 ic_d_delete"></span>
            </span>
          </span>
        )}
      </div>
    </div>
  );
}

export default Header;
