import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { Select } from '../../../foundation';
import { useActionsContext, useValuesContext } from './Context';
import { refreshRate } from './types';

const gadgetRefreshRate = [
  {
    value: refreshRate.none,
    title: '안함',
  },
  {
    value: refreshRate.oneMin,
    title: '1분',
  },
  {
    value: refreshRate.fiveMin,
    title: '5분',
  },
  {
    value: refreshRate.tenMin,
    title: '10분',
  },
  {
    value: refreshRate.fifteenMin,
    title: '15분',
  },
  {
    value: refreshRate.thirtyMin,
    title: '30분',
  },
  {
    value: refreshRate.oneHour,
    title: '1시간',
  },
  {
    value: refreshRate.twoHour,
    title: '2시간',
  },
];

export interface Props {
  /** @property 가젯 새로고침 옵션 여부 (기본 - true)*/
  isRefreshable?: boolean;
  /** @property 가젯 테두리 강조 옵션 여부 (기본 - true)*/
  isHighlightable?: boolean;
  /** @property 가젯 에러 메시지 */
  errorMessage?: string;
}

export function Config({
  isRefreshable = true,
  isHighlightable = true,
  errorMessage,
  children,
}: PropsWithChildren<Props>) {
  const { uuid, config, isEditing } = useValuesContext();
  const { setIsEditing, onChangeConfig, onSave, onCancel } =
    useActionsContext();

  const handleOnCancel = () => {
    setIsEditing(false);
    onCancel();
  };

  const handleOnSave = () => {
    if (onSave) setIsEditing(!onSave(config));
    else setIsEditing(false);
  };

  const refreshOption = isRefreshable && (
    <ul className="static_style">
      <li>
        <p className="title">새로고침</p>
        <Select
          selectType="system"
          options={gadgetRefreshRate}
          defaultOption={
            config.refresh
              ? gadgetRefreshRate
                  .filter((item) => {
                    return item.value === config.refresh;
                  })
                  .pop() ?? { title: '안함', value: refreshRate.none }
              : { title: '안함', value: refreshRate.none }
          }
          onChangeValue={(value) => {
            onChangeConfig({ refresh: value });
          }}
        />
      </li>
    </ul>
  );

  const highlightOption = isHighlightable && (
    <ul className="static_style">
      <li>
        <p className="title">가젯 테두리</p>
        <input
          id={`gadget-highlight-option-${uuid}`}
          type="checkbox"
          checked={config.highlight ?? false}
          onChange={(e) => {
            onChangeConfig({
              highlight: e.target.checked,
            });
          }}
        />
        <label htmlFor={`gadget-highlight-option-${uuid}`}>강조</label>
      </li>
    </ul>
  );

  const errorBlock = errorMessage && (
    <p className="desc">
      <span className="txt_caution error-msg-wrapper">{errorMessage}</span>
    </p>
  );

  return (
    <div
      className={clsx('go-gadget-config gadget_edit', {
        wrap_gadget_edit: isEditing,
      })}
    >
      {errorBlock}
      <form className="gadget-options-form">
        {refreshOption}
        <ul className="static_style">{children}</ul>
        {highlightOption}
      </form>
      <footer className="btn_layer_wrap">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <a
          className="btn_major_s btn-option-save"
          onClick={() => handleOnSave()}
        >
          <span className="txt">저장</span>
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <a
          className="btn_minor_s btn-option-cancel"
          onClick={() => handleOnCancel()}
        >
          <span className="txt">취소</span>
        </a>
      </footer>
    </div>
  );
}

export default Config;
