import * as React from 'react';
import type {
  DashboardProps,
  GadgetApiParams,
  GadgetSpecProps,
  GadgetComponentType,
} from './types';

export interface DashboardValuesContextType {
  readonly dashboards: Array<DashboardProps>;
  readonly gadgetSpecs: Array<GadgetSpecProps>;
  readonly originGadgets: Array<GadgetApiParams>;
  /** @property 현재 대시보드의 가젯 목록 (읽기 및 생성/수정 모드에서 동일하게 사용) */
  readonly gadgets: Array<GadgetApiParams>;
  /** @property 현재 대시보드 */
  readonly currentDashboard: DashboardProps | null;
  /** @property 이전 대시보드 (생성 액션 후 취소를 눌렀을 때 이전 대시보드로 복원시키기 위한 용도) */
  readonly prevDashboard: DashboardProps | null;
  // readonly isFirstCreate: boolean;
  /** @property 대시보드가 수정 모드인지 여부 */
  readonly isEditMode: boolean;
}

export interface DashboardActionsContextType {
  getGadgetComponent: (pkgName: string) => GadgetComponentType | undefined;
  getGadgetThumbnail: (pkgName: string) => string | undefined;
  setDashboards: React.Dispatch<React.SetStateAction<Array<DashboardProps>>>;
  addDashboards: (param: DashboardProps | Array<DashboardProps>) => void;
  removeDashboard: (id: number) => void;
  toggleDashboard: (id: number) => void;
  editDashboard: (dashboard: DashboardProps) => void;
  setGadgetSpecs: React.Dispatch<React.SetStateAction<Array<GadgetSpecProps>>>;
  setOriginGadgets: React.Dispatch<
    React.SetStateAction<Array<GadgetApiParams>>
  >;
  setGadgets: (gadgets: GadgetApiParams[]) => void;
  addGadget: (newGadget: GadgetApiParams) => void;
  editGadget: (newGadget: GadgetApiParams) => void;
  removeGadget: (uuid: string) => void;
  getCompanyDashboard: () => DashboardProps | undefined;
  setCurrentDashboard: (dashboard: DashboardProps | null) => undefined;
  setPrevDashboard: (dashboard: DashboardProps | null) => undefined;
  getGadgetsByColumnSeq: (column: number) => Array<GadgetApiParams> | undefined;
  isPreventDrop: (gadet: GadgetApiParams) => boolean;
  reorderGadgets: (
    gadget: GadgetApiParams,
    column: number,
    seq: number,
  ) => void;
  setEditMode: (bool: boolean) => void;
}

export const DashboardValuesContext =
  React.createContext<DashboardValuesContextType>({
    currentDashboard: null,
    prevDashboard: null,
    dashboards: [],
    gadgetSpecs: [],
    originGadgets: [],
    gadgets: [],
    // isFirstCreate: false,
    isEditMode: false,
  });

export const DashboardActionsContext =
  React.createContext<DashboardActionsContextType>({
    getGadgetComponent: () => undefined,
    getGadgetThumbnail: () => undefined,
    setDashboards: () => undefined,
    addDashboards: () => undefined,
    removeDashboard: () => undefined,
    toggleDashboard: () => undefined,
    editDashboard: () => undefined,
    setGadgetSpecs: () => undefined,
    setOriginGadgets: () => undefined,
    setGadgets: () => undefined,
    addGadget: () => undefined,
    editGadget: () => undefined,
    removeGadget: () => undefined,
    getCompanyDashboard: () => undefined,
    setCurrentDashboard: () => undefined,
    setPrevDashboard: () => undefined,
    getGadgetsByColumnSeq: () => undefined,
    isPreventDrop: () => false,
    reorderGadgets: () => undefined,
    setEditMode: () => undefined,
  });

export const useDashboardValuesContext = () => {
  return React.useContext(DashboardValuesContext);
};

export const useDashboardActionsContext = () => {
  return React.useContext(DashboardActionsContext);
};

export function useDashboardContext() {
  const state = useDashboardValuesContext();
  const actions = useDashboardActionsContext();

  return {
    state,
    actions,
  };
}
